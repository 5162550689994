import * as React from 'react'
import Layout from '../../components/layout'
import {graphql, Link} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Seo from "../../components/Seo"

const BlogPage = ({data}) => {

    return (
        <Layout pageTitle="My Blog Posts">
            <Seo title="Liste des posts" />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 max-w-screen-md mx-auto mt-4">
                {
                    data.allMdx.nodes.map(n => {
                            let baliseimage
                            if (n.frontmatter.hero_image.extension !== "svg") {
                                const image = getImage(n.frontmatter.hero_image)
                                baliseimage = <GatsbyImage
                                    image={image}
                                    alt={n.frontmatter.hero_image_alt}
                                    className="w-full h-64"
                                />
                            } else {
                                baliseimage = <img src={n.frontmatter.hero_image.publicURL}
                                                   alt={n.frontmatter.hero_image_alt}
                                                   className="w-full h-64"
                                />
                            }
                            return (<div key={n.id} className="mb-4">
                                <Link to={"/blog/" + n.slug} className="no-underline visited:text-black group">
                                    { baliseimage }
                                    <div className="mt-2 font-thin uppercase text-black/25 text-base !no-underline">{n.frontmatter.label}</div>
                                    <div className="group-hover:underline font-extrabold mt-2 text-2xl underline-offset-4 decoration-8 decoration-amber-500">{n.frontmatter.title}</div>
                                </Link>
                            </div>)
                        }
                    )
                }
            </div>
        </Layout>
    )
}
export const query = graphql`
    query {
        allMdx(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                frontmatter {
                    hero_image {
                        childImageSharp {
                            gatsbyImageData(width: 1000)
                        }
                        extension
                        publicURL
                    }
                    title
                    date(formatString: "D MMMM YYYY")
                    label
                }
                id
                slug
            }
        }
    }
`

export default BlogPage
